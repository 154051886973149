import its_us from '../its_us.png'


function Main() {
    return(
        <div>
            <div class="two-cols">
                    <h1 class="header">Stay tuned!</h1>
                    <img class="beautiful-people" src={its_us} alt="Picture of two attractive people in front of a house" />

                <p>We bought an old house in Central Iowa, and we're going to tell you all about it.</p>
                </div>
        </div>
    )
}

export default Main
